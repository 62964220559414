import styled from "styled-components";
import { getMaxHeight, getMaxWidth } from "utils/styling.utils";

import { FourZeroFourStyledProps as Props } from "./FourZeroFour.types";

const FourZeroFourStyled = styled.div<Props>`
  width: ${getMaxWidth()};
  height: ${getMaxHeight()};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--palette-cream-s42-l93);

  .FourZeroFour {
    &__main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4rem;
    }

    &__info {
      color: var(--palette-blue-s51-l16);
      font-size: 2rem;
      line-height: 2.8rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    &__description {
      color: var(--palette-blue-s51-l16);
      font-size: 3.2rem;
      line-height: 2.8rem;
      text-align: center;
      font-weight: 600;
      margin-bottom: 4rem;
    }

    &__button {
      font-size: 1.6rem;
      font-weight: 600;
      padding: 1.7rem 3.8rem;
      border-radius: 10rem;
    }
  }
`;

export default FourZeroFourStyled;
