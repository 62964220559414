import React from "react";
import { useRouter } from "next/router";

import Styles from "./FourZeroFour.styles";
import { FourZeroFourProps as Props } from "./FourZeroFour.types";
import Button from "components/global/Button/Button";

import ErrorSVG from "../../../../public/assets/images/Errors/error-404.svg";

const FourZeroFour: React.FC<Props> = props => {
  const router = useRouter();

  return (
    <Styles className="FourZeroFour">
      <main className="FourZeroFour__main">
        <div className="FourZeroFour__icon">
          <ErrorSVG />
        </div>
        <p className="FourZeroFour__info">Error 404</p>
        <p className="FourZeroFour__description">¡Página no encontrada!</p>
        <Button
          color="primary"
          className="FourZeroFour__button"
          isLoading={false}
          onClick={() => router.push("/")}
        >
          Volver a inicio
        </Button>
      </main>
    </Styles>
  );
};

FourZeroFour.defaultProps = {};

export default FourZeroFour;
